<template>

</template>

<script type="text/babel">
    export default {
        name: "BrivoRedirect",
        data() {
            return {

            }
        },
        created(){



//            this.$http.post('https://auth.brivo.com/oauth/authorize?response_type=code&client_id=2587d849-6927-40bc-9c1f-bf7b3c2e9346', {
//
//            })
//            this.$router.go()
            window.location = 'https://auth.brivo.com/oauth/authorize?response_type=code&client_id=2587d849-6927-40bc-9c1f-bf7b3c2e9346'

        }
    }


</script>